import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <p className="center error">
          The webpage encountered an error. Please refresh the page and try
          again.
        </p>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
