import React, { useEffect, useMemo, useState, useRef } from "react";
import ReactWebChat, {
  createDirectLine,
  createStore,
} from "botframework-webchat";
import { v4 as uuid } from "uuid";
import jwtDecode from "jwt-decode";
import ReactModal from "react-modal";
import Suggestions from "./Suggestions";
import styleOptions from "./StyleOptions";
import CustomAttachments from "./CustomAttachments";
import decrypt from "../utils/decrypt";
import { getNewToken, getRefreshToken } from "./Api";
import logo from "../svg/suzie-logo.png";
import notificationAudio from "../audio/mixkit-software-interface-back-2575.wav";
import { ReactComponent as SuggestionIcon } from "../svg/suggestion.svg";
import headerStyles from "./header.module.css";

const suggestionList = [
  { label: "Help", desc: "Help" },
  { label: "Change the language", desc: "Change preferred language" },
];
ReactModal.setAppElement("#root");

const WebChatRenderer = () => {
  const [show, setShow] = useState(false);
  const [userId] = useState(sessionStorage.getItem("userId") || uuid());
  const [activity, setActivity] = useState(null);
  const userInteracted = useRef(false);
  const [showPopup, setShowPopup] = useState(false);
  const [awayMessageCounter,setAwayMessageCounter]=useState(0);
  const tabIsBlurred=useRef(false);
  const timerRef = useRef(null);
  const audio=useMemo(()=>new Audio(notificationAudio),[]);
  const onFocus = () => {
    tabIsBlurred.current=false;
    setAwayMessageCounter(0);
};
const onBlur = () => {
  tabIsBlurred.current=Date.parse(new Date())-4000;
};

useEffect(()=>{
  const element = document.getElementsByClassName('webchat__send-box-text-box__input')
  element[0]?.setAttribute("maxlength", 3000);
}, [])

useEffect(()=>{
if(awayMessageCounter)
  {
document.title=`(${awayMessageCounter}) Suzie`
  }
  else
  {
    document.title=`Suzie`
  }
},[awayMessageCounter])
useEffect(() => {
  window.addEventListener("focus", onFocus);
  window.addEventListener("blur", onBlur);
  // Calls onFocus when the window first loads
  onFocus();
  // Specify how to clean up after this effect:
  return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
  };
}, []);

  useEffect(() => {
    if (userId) sessionStorage.setItem("userId", userId);
  }, [userId]);

  const documentMouseDownHandler = () => {
    userInteracted.current=true
  }
  useEffect(() => {
    audio.addEventListener('ended', () => audio.pause())
    document.body.addEventListener("mousedown",documentMouseDownHandler)
    document.body.addEventListener("keydown",documentMouseDownHandler) 
    document.body.addEventListener("touchstart",documentMouseDownHandler)
    return () => {
        audio.removeEventListener('ended', () => audio.pause())
        document.body.removeEventListener("mousedown",documentMouseDownHandler)
        document.body.removeEventListener("keydown",documentMouseDownHandler) 
        document.body.removeEventListener("touchstart",documentMouseDownHandler)
    };
}, []);
  const [token, setToken] = useState(sessionStorage.getItem("webchat_token"));

  const store = useMemo(() => {
    return createStore({}, () => (next) => (action) => {
      if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
        const { activity } = action.payload;
        if (activity.type === "message") {
          if(activity.from.role!="user" && userInteracted.current)
          {
            audio.play();
            if(tabIsBlurred.current && 
              tabIsBlurred.current < Date.parse(new Date(activity.timestamp)))
            {
              setAwayMessageCounter(counter => counter+1);
            }
          }
          //Enable all buttons
          const buttons = document.querySelectorAll("ul[role='list'] button");
          buttons.forEach((button) => {
            if (
              !(
                button.innerText.toLowerCase().includes("agent") &&
                new Date().getTime() >
                  Number(button.id.slice(button.innerText.length)) +
                    59 * 60 * 1000
              )
            )
              button.disabled = false;
          });
          const el = document.querySelector("form input");
          if (document.activeElement !== el) {
            el.focus();
          }
        }
        document.querySelector('ul[role="list"]')?.lastChild?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else if (action.type === "DIRECT_LINE/POST_ACTIVITY") {
        //Disable all buttons
        const buttons = document.querySelectorAll("ul[role='list'] button");
        buttons.forEach((button) => {
          button.disabled = true;
        });
      }
      return next(action);
    });
  }, [audio]);

  const directLine = useMemo(
    () =>
      createDirectLine({
        token,
      }),
    [token]
  );

  useEffect(() => {
    const checkTokenValidity = (event) => {
      const { webchat_token } = sessionStorage;

      if (webchat_token) {
        const decoded = jwtDecode(webchat_token);

        if (new Date().getTime() < decoded.exp * 1000) {
          const diff = new Date().getTime() - decoded.exp * 1000;
          if (diff < 22 * 60 * 1000 && diff > 0.5 * 60 * 1000) {
            getRefreshToken(onReceiveRefreshToken, checkTokenValidity);
          }

          //Reschedule refresh token after page reload
          if (event?.type !== "focus") {
            if (timerRef.current) {
              clearInterval(timerRef.current);
            }
            timerRef.current = setInterval(() => {
              getRefreshToken(onReceiveRefreshToken, checkTokenValidity);
            }, 20 * 60 * 1000);
          }
          return;
        } else {
          setShowPopup(true);
        }
      }
    };

    const onReceiveNewToken = (encryptedData) => {
      const decrypted = decrypt(encryptedData);
      const data = JSON.parse(decrypted);
      if (data?.token) {
        sessionStorage.setItem("webchat_token", data.token);
        sessionStorage.setItem("conversationId", data.conversationId);
        setToken(data.token);

        //Schedule refresh token
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }

        timerRef.current = setInterval(() => {
          getRefreshToken(onReceiveRefreshToken, checkTokenValidity);
        }, 20 * 60 * 1000);
      }
    };

    const { webchat_token } = sessionStorage;
    if (!webchat_token)
      getNewToken(
        {
          user: { id: "dl_" + userId, name: "User" },
        },
        onReceiveNewToken
      );

    const onReceiveRefreshToken = (encryptedData) => {
      const decrypted = decrypt(encryptedData);
      const data = JSON.parse(decrypted);
      if (data?.token) {
        sessionStorage.setItem("webchat_token", data.token);
        sessionStorage.setItem("conversationId", data.conversationId);
        // setToken(data.token);
      }
    };

    if (webchat_token) checkTokenValidity();
    window.addEventListener("focus", checkTokenValidity);

    return () => {
      window.removeEventListener("focus", checkTokenValidity);
    };
  }, []);

  const attachmentMiddleware = () => (next) => (card) => {
    return (
      <CustomAttachments
        card={card}
        next={next}
        store={store}
        activity={activity}
        setActivity={setActivity}
      />
    );
  };

  return (
    <>
      <ReactWebChat
        directLine={directLine}
        userID={userId}
        styleOptions={styleOptions}
        store={store}
        attachmentMiddleware={attachmentMiddleware}
      />

      <button
        type="button"
        onClick={() => setShow(true)}
        className="flex-row flex-align-center suggestion-button"
        title="Suggestions"
        aria-haspopup="true"
        aria-expanded={show ? "true" : "false"}
        tabIndex="0"
      >
        <SuggestionIcon width={20} height={20} className="suggestion-icon" />
      </button>
      <Suggestions
        aria={{ "aria-expanded": show ? "true" : "false" }}
        show={show}
        setShow={setShow}
        store={store}
        suggestionList={suggestionList}
      />

      <ReactModal
        isOpen={showPopup}
        onRequestClose={() => {
          setShowPopup(false);
          sessionStorage.clear();
        }}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        className="modal-popup"
      >
        <div className="h100 flex-column session-exp-popup">
          <div className="flex-row flex-align-center">
            <img src={logo} alt="App Logo" width="30px" height="30px" />
            <span className={headerStyles.logotext}>Suzie</span>
          </div>
          <div className="flex-column flex-align-center flex-just-center popup-main-content">
            <h2>Session expired!!</h2>
            <div>
              Session expired. Please click on Start New Conversation to start a
              new session.
            </div>
            <button
              type="button"
              className="ad-card-button"
              onClick={() => {
                sessionStorage.clear();
                window.location.reload();
              }}
            >
              Start New Conversation
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
};
export default WebChatRenderer;
