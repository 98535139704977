import React from "react";
import Home from "./components/Home.js";
import ErrorBoundary from "./components/ErrorBoundary";
import NetworkDetector from "./components/NetworkDetector.js";
import "./App.css";

export default function App() {
  return (
    <ErrorBoundary>
      <NetworkDetector>
        <Home />
      </NetworkDetector>
    </ErrorBoundary>
  );
}
