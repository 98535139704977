import React, { useEffect, useRef } from "react";
import setSendBox from "botframework-webchat-core/lib/actions/setSendBox";
import styles from "./suggestion.module.css";

function Suggestions({ store, show, setShow, suggestionList, aria }) {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShow]);

  return (
    <div>
      {show && (
        <div className={`flex-column ${styles.container}`} ref={ref} {...aria}>
          <h4 className="text-center">Suggestions</h4>
          {suggestionList.map((item) => (
            <button
              type="button"
              className={styles.button}
              key={item.label}
              onClick={() => {
                store.dispatch(setSendBox(item.label));
                setShow(false);
                document.querySelector("form input").focus();
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
export default Suggestions;
