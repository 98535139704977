import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function NetworkDetector(props) {
  const [isDisconnected, setIsDisconnected] = useState(null);

  useEffect(() => {
    let webPing;
    const handleConnectionChange = () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        webPing = setInterval(() => {
          fetch("//google.com", {
            mode: "no-cors",
          })
            .then(() => {
              setIsDisconnected((prev) => {
                if (prev === true) {
                  toast.dismiss();
                  toast.success("Internet connection is live again!");
                }
                return false;
              });
              // return clearInterval(webPing);
            })

            .catch(() => {
              setIsDisconnected((s) => {
                if (!s)
                  toast.error(
                    "Network error.Please check your internet connection.",
                    { autoClose: false, closeButton: false }
                  );
                return true;
              });
            });
        }, 5000);
        return;
      }
      toast.dismiss();
      toast.error("Network error.Please check your internet connection.", {
        autoClose: false,
        closeButton: false,
      });
      return setIsDisconnected(true);
    };
    handleConnectionChange();

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
      clearInterval(webPing);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <>{props.children}</>
    </>
  );
}
