import { UserAvatar, BotAvatar } from "../svg/Avatars";

const WebchatStyleOptions = {
  fontSizeSmall: "70%",
  rootHeight: "100%",
  rootWidth: "100%",
  margin: "0 auto",
  backgroundColor: "#f5f5f5",
  primaryFont: "'Quicksand',sans-serif",
  hideUploadButton: true,
  userAvatarBackgroundColor: "#f5f5f5",
  botAvatarBackgroundColor: "#f5f5f5",
  botAvatarImage: BotAvatar,
  userAvatarImage: UserAvatar,
  userAvatarInitials: "",
  botAvatarInitials: "",
  bubbleBackground: "#fff",
  bubbleFromUserBackground: "#fff",
  bubbleBorderRadius: "0px 15px 15px 15px",
  bubbleFromUserBorderRadius: "15px 15px 0px 15px",
  bubbleMinHeight: 20,
  transcriptActivityVisualKeyboardIndicatorColor: "#f5f5f5",
  transcriptVisualKeyboardIndicatorColor: "#f5f5f5",
  sendBoxMaxHeight: 60,
  timestampColor: "#333",
  //hideScrollToEndButton: true,
};
export default WebchatStyleOptions;
