import React from "react";
import logo from "../svg/suzie-logo.png";
import styles from "./header.module.css";

function Header() {
  return (
    <header className={`flex-row flex-align-center ${styles.container}`}>
      <div className="flex-row flex-align-center">
        <img src={logo} alt="App Logo" width="30px" height="30px" />
        <span className={styles.logotext}>Suzie</span>
      </div>
    </header>
  );
}
export default Header;
