const baseUrl = process.env.REACT_APP_BASE_URL;

export function getNewToken(payload, successCallback, errorCallback) {
  fetch(`${baseUrl}/api/Token/generate`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) =>
      res.text().then((encryptedData) => successCallback(encryptedData))
    )
    .catch(() => errorCallback && errorCallback());
}

export function getRefreshToken(successCallback, errorCallback) {
  fetch(`${baseUrl}/api/Token/refresh`, {
    method: "POST",
    headers: {
      Authorization: sessionStorage.webchat_token,
    },
  }).then((res) => {
    res
      .text()
      .then((encryptedData) => successCallback(encryptedData))
      .catch(() => errorCallback());
  });
}
