import React, { useState } from "react";
import sendMessage from "botframework-webchat-core/lib/actions/sendMessage";
import sendPostBack from "botframework-webchat-core/lib/actions/sendPostBack";

export default function CustomAttachments({
  card,
  next,
  store,
  activity,
  setActivity,
}) {
  const attachmentBody = card.attachment.content.body;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const actionData = attachmentBody?.find((item) => item.type === "ActionSet")
    ?.actions[0]?.data;

  switch (card.attachment.contentType) {
    case "application/vnd.microsoft.card.adaptive":
      if (card.activity.attachments?.length === 3) {
        return (
          <MulticardAttachment
            attachmentBody={attachmentBody}
            store={store}
            card={card}
            actionData={actionData}
          />
        );
      } else {
        const cardActions = card.attachment.content.body[1].actions || card.attachment.content.body[2]?.actions;
        const isRatingCard = card.attachment.content.body[1].type === "Input.ChoiceSet";
        const feedbackCard = cardActions?.find(
          (item) => item.type === "Action.ShowCard"
        )?.card;

        if (isRatingCard) {
          return (
            <RatingCardAttachment
              store={store}
              card={card}
              feedbackCard={feedbackCard}
              activity={activity}
              setActivity={setActivity}
              cardActions={cardActions}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
            />
          );
        }
        else if (feedbackCard) {
          return (
            <FeedbackAttachment
              store={store}
              card={card}
              feedbackCard={feedbackCard}
              activity={activity}
              setActivity={setActivity}
              cardActions={cardActions}
            />
          );
        }
      }
      return next(card);
    // case "application/vnd.microsoft.card.hero":
    //   return next(card);
    default:
      return next(card);
  }
}
function MulticardAttachment({ attachmentBody, card, actionData, store }) {
  const buttonText = attachmentBody[4].actions[0].title;

  //Disable "Connect to Agent" buttons older than 59 mins
  const isDisabled =
    buttonText?.toLowerCase().includes("agent") &&
    new Date().getTime() - new Date(card.activity.timestamp).getTime() >
    59 * 60 * 1000;

  function handleButtonClick() {
    if (isDisabled) return;
    typeof actionData === "string"
      ? store.dispatch(sendMessage(actionData))
      : store.dispatch(sendPostBack(actionData));
  }

  return (
    <div className="flex-column h100 carousel-card">
      <div>
        <h2>{attachmentBody[0].text}</h2>
        <div>
          <img src={attachmentBody[1].url} alt=""></img>
        </div>
        <p>{attachmentBody[2].text}</p>
        <p className="carousel-card-sec-text">{attachmentBody[3].text}</p>
      </div>
      <button
        id={buttonText + new Date(card.activity.timestamp).getTime()}
        type="button"
        className="ad-card-button"
        onClick={handleButtonClick}
        disabled={isDisabled}
      >
        {buttonText}
      </button>
    </div>
  );
}
function FeedbackAttachment({
  store,
  card,
  feedbackCard,
  cardActions,
  activity,
  setActivity,
}) {
  function submitResponse() {
    const comment = document.getElementById(
      "textarea" + card.activity.id
    )?.value;
    store.dispatch(
      sendPostBack({
        ...feedbackCard.body[1].actions[0].data,
        comment,
      })
    );
  }

  return (
    <div className="flex-column feedback-card">
      <p>{card.attachment.content.body[0].text}</p>
      <button
        type="button"
        onClick={() => {
          activity && setActivity(null);
          store.dispatch(sendPostBack(cardActions[0].data));
        }}
        className="ad-card-button"
      >
        {cardActions[0].title}
      </button>
      <button
        type="button"
        onClick={() =>
          setActivity((id) =>
            id === card.activity.id ? null : card.activity.id
          )
        }
        className="ad-card-button"
      >
        {cardActions[1].title}
      </button>
      <div
        className="flex-column feedback-section"
        style={activity !== card.activity.id ? { display: "none" } : {}}
      >
        <textarea
          id={"textarea" + card.activity.id}
          placeholder={feedbackCard.body[0].placeholder}
          maxLength="200"
        ></textarea>
        <small style={{ textAlign: "right", color: "grey" }}>
          {card.activity.locale === "nl"
            ? "Maximaal 200 karakters" : card.activity.locale === "de" ? "Maximal 200 Zeichen"
              : "Max. 200 characters"}
        </small>
        <button
          type="button"
          onClick={submitResponse}
          className="ad-card-button"
        >
          {feedbackCard.body[1].actions[0].title}
        </button>
      </div>
    </div>
  );
}
function RatingCardAttachment({
  store,
  card,
  feedbackCard,
  cardActions,
  activity,
  setActivity,
  isSubmitted,
  setIsSubmitted
}) {
  function submitResponse(e) {
    const isRatingSelected = document.querySelector(`input[name="${e}"]:checked`);
    if (isRatingSelected === null) {
      var message = card.activity.locale === "nl"
        ? "Kies een beoordeling alstublieft" : card.activity.locale === "de" ? "Bitte wählen Sie eine Bewertung"
          : "Please choose a rating";
      document.getElementById("ratingerrormessage" + e).innerHTML = message;
    }
    else {
      if (!isSubmitted) {
        setIsSubmitted(true);
        const rating = document.querySelector(`input[name="${e}"]:checked`)?.value;
        document.getElementById("ratingerrormessage" + e).innerHTML = "";
        const comment = document.getElementById(
          "textarea" + card.activity.id
        )?.value;
        store.dispatch(
          sendPostBack({
            ...feedbackCard.body[1].actions[0].data,
            comment, rating,
          })
        );
      }
    }
  }

  return (
    <div className="flex-column feedback-card">
      <p>{card.attachment.content.body[0].text}</p>

      <div className="radio_button_group" >
        <input className="radio_button" type="radio" name={card.attachment.content.body[1].id} value="Excellent" />
        <label >{card.attachment.content.body[1].choices[0].title}</label> <br />
        <input className="radio_button" type="radio" name={card.attachment.content.body[1].id} value="Good" />
        <label>{card.attachment.content.body[1].choices[1].title} </label><br />
        <input className="radio_button" type="radio" name={card.attachment.content.body[1].id} value="Average" />
        <label>{card.attachment.content.body[1].choices[2].title} </label><br />
        <input className="radio_button" type="radio" name={card.attachment.content.body[1].id} value="Poor" />
        <label>{card.attachment.content.body[1].choices[3].title} </label><br />
        <input className="radio_button" type="radio" name={card.attachment.content.body[1].id} value="Very Poor" />
        <label>{card.attachment.content.body[1].choices[4].title} </label><br />
      </div>
      <div id={"ratingerrormessage" + card.attachment.content.body[1].id} className="ratingcard-error-message"></div>
      <button
        type="button"
        onClick={() =>
          setActivity((id) =>
            id === card.activity.id ? null : card.activity.id
          )
        }
        className="ad-card-button"
      >
        {cardActions[0].title}
      </button>
      <div
        className="flex-column feedback-section"
        style={activity !== card.activity.id ? { display: "none" } : {}}
      >
        <textarea
          id={"textarea" + card.activity.id}
          placeholder={feedbackCard.body[0].placeholder}
          maxLength="200"
        ></textarea>
        <small style={{ textAlign: "right", color: "grey" }}>
          {card.activity.locale === "nl"
            ? "Maximaal 200 karakters" : card.activity.locale === "de" ? "Maximal 200 Zeichen"
              : "Max. 200 characters"}
        </small>
        <button
          type="button"
          onClick={e => submitResponse(card.attachment.content.body[1].id)}
          className={isSubmitted ? 'comment-button-disable' : 'ad-card-button'}
          disabled={isSubmitted}
        >
          {feedbackCard.body[1].actions[0].title}
        </button>
      </div>
    </div>
  );
}
