import CryptoJS from "crypto-js";

export default function decrypt(transitmessage) {
  var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY);
  var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV);

  var decrypted = CryptoJS.AES.decrypt(
    transitmessage.slice(1, transitmessage.length - 1),
    key,
    {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}
