import React from "react";
import Header from "./Header";
import WebChatRenderer from "./WebChatRenderer";
export default function Home() {
  return (
    <div className="flex-column h100 container">
      <Header username="User" />
      <div className="h100 webchat-container">
        <WebChatRenderer />
      </div>
    </div>
  );
}
